import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useTenantStore } from '/@stores/tenant';
import { Modules, ProjectProperties } from '/@types/ids';
import { api, createQueryParams, filterQuery, genericApiErrorHandler } from '/@utilities/api';
import { awaitUser } from '/@stores/user';
import { Opt } from '/@types/opt';
import {
  Project,
  ProjectType,
  ProjectProperty,
  ProjectColumn,
  ProjectColumnKeys,
  LoadProjectPageOpts,
} from './project.types';
import {
  mapProject,
  mapProjectProperty,
  mapProjectTable,
  mapProjectType,
  mapValuelist,
} from './project.mappers';

export function initDependencies(columns: ProjectColumn[]) {
  const loadMapper = columns.map(async (i) => {
    if (!i.dependencies?.length) return;
    i._resolvedDependencies = await Promise.all(
      i.dependencies.map((maybeFn) => (typeof maybeFn === 'function' ? maybeFn() : maybeFn)),
    );
  });
  return Promise.all(loadMapper).then(() => columns);
}

export const useProjectStore = defineStore('project', () => {
  const tenantStore = useTenantStore();

  const projects = ref<Map<number, Project>>(new Map());

  function loadProjectById(id: number): Promise<Project> {
    return api
      .get(`v1/projects/${id}`)
      .then(({ data }) => {
        const project = mapProject(data);
        projects.value.set(data.Id, project);
        return project;
      })
      .catch(genericApiErrorHandler);
  }

  type UserProject = { name: string; hasUserFilter: boolean; registrationsLocked: boolean };

  function loadProjectsByUser(userId: number): Promise<Map<number, UserProject>> {
    return api
      .get(`timer/users/${userId}/projects`)
      .then(({ data: projects }) => {
        return new Map(
          projects.map((p: any) => [
            p.ProjectId,
            {
              name: p.ProjectName,
              hasUserFilter: p.HasProjectUserFilter,
              registrationsLocked: p.RegistrationsLocked,
            },
          ]),
        );
      })
      .catch(genericApiErrorHandler);
  }

  async function updateProject(id: number, updates: Partial<Project>) {
    const { data: updated } = await api.get(`v1/projects/${id}`);

    for (const key in updates) {
      const value = updates[key];

      switch (key) {
        case 'name':
          updated.Name = value;
          break;
        case 'colorId':
          updated.ColorId = value;
          break;
        case 'description':
          updated.Description = value;
          break;
        case 'installer':
          updated.Installer = value;
          break;
      }
    }
    return api
      .put(`v1/projects/${id}`, updated)
      .then(() => loadProjectById(id))
      .catch(genericApiErrorHandler);
  }

  const projectTypes = ref<Map<number, ProjectType>>(new Map());

  async function loadProjectTypes(moduleId = Modules.Timer): Promise<Map<number, ProjectType>> {
    const user = await awaitUser;
    return api
      .get(`modules/${moduleId}/projecttypes`)
      .then(({ data }) => {
        projectTypes.value = new Map(
          data
            .filter((t: any) => t.ActiveForTenantIds.includes(user.tenant.id))
            .map((t: any) => [t.Id, mapProjectType(t)]),
        );
        return projectTypes.value;
      })
      .catch(genericApiErrorHandler);
  }

  function loadStatuses({
    moduleIds,
    projectTypeIds,
  }: {
    moduleIds?: Modules[];
    projectTypeIds?: number[];
  }): Promise<Map<number, { name: string }>> {
    return api
      .get('v2/projects/statuses', {
        params: createQueryParams(
          new Map([
            ['moduleIds', moduleIds],
            ['projectTypeIds', projectTypeIds],
          ]),
        ),
      })
      .then(
        ({ data }) =>
          new Map(
            data
              .filter((s: any) => s.Active && s.ProjectStatusGroupId === 1)
              .sort((a: any, b: any) => a.SortIndex - b.SortIndex)
              .map((status: any) => [status.Id, { name: status.Name }]),
          ),
      )
      .catch(genericApiErrorHandler);
  }

  function updateStatus(projectId: number, statusId: number) {
    return api
      .post(`v1/projects/${projectId}/statusId`, { StatusId: statusId })
      .catch(genericApiErrorHandler);
  }

  function loadValuelistByGuid(valuelistGuid: string) {
    return api
      .get(`tenants/valuelists/${valuelistGuid}`)
      .then(({ data }) => mapValuelist(valuelistGuid, data))
      .catch(genericApiErrorHandler);
  }

  async function getProjectColumns({
    moduleId,
    opt,
  }: {
    moduleId: Modules;
    opt: Opt;
  }): Promise<ProjectColumn[]> {
    const types = await loadProjectTypes();
    // ressursstatus, ressurskommentar
    const propertiesFiltered = [
      ...new Map(
        [...types.values()]
          .flatMap((p) => p.properties)
          .filter((p) =>
            [
              ProjectProperties.RessursPrioritet,
              ProjectProperties.RessursKommentar,
              ProjectProperties.PlanlagtStart,
              ProjectProperties.PlanlagtFerdig,
            ].includes(p.id),
          )
          .map((p) => [p.id, p]),
      ).values(),
    ];

    async function loadCounts(opt: Opt, columns: ProjectColumn[], col: ProjectColumn) {
      return await loadValueListCount(col.filterKey, moduleId, opt, columns);
    }

    return [
      {
        id: ProjectColumnKeys.StatusGroup,
        label: 'Statusgruppe',
        filterKey: 'statusgroupid',
      },
      {
        id: ProjectColumnKeys.TypeId,
        label: 'Prosjekttype',
        filterKey: 'typeId',
      },
      {
        id: ProjectColumnKeys.Caseworker,
        label: 'Saksbehandler',
        filterKey: 'caseworkerIds',
        filterListCount: loadCounts,
        filterList: ([caseworkerList]) => {
          return [...caseworkerList].map(([id, c]) => ({
            id,
            label: c.userName,
            value: id,
          }));
        },
        dependencies: [() => tenantStore.loadCaseworkers()],
      },
      {
        id: ProjectColumnKeys.Department,
        label: 'Avdeling',
        filterKey: 'departmentIds',
        filterListCount: loadCounts,
        filterList: ([departmentList]) => {
          return [...departmentList].map(([id, label]) => ({
            id,
            label,
            value: id,
          }));
        },
        dependencies: [() => tenantStore.loadDepartments()],
      },
      {
        id: ProjectColumnKeys.Ref2,
        label: 'Internnummer',
        filterKey: 'referenceNo2',
      },
      {
        id: ProjectColumnKeys.StatusId,
        label: 'Status',
        filterKey: 'statusIds',
        filterListCount: loadCounts,
        filterList: ([list]) => {
          return [...list].map(([id, c]) => ({
            id,
            label: c.name,
            value: id,
          }));
        },
        dependencies: [() => loadStatuses({ moduleIds: [Modules.Timer, Modules.Ressurs] })],
      },

      ...propertiesFiltered.map((p) => ({
        id: p.id,
        label: p.name,
        filterKey: p.id,
        ...(p.valuelistGuid
          ? {
              filterListCount: loadCounts,
              filterList: ([list]) => {
                return [...list.valuelistItems].map(([id, i]) => ({
                  id,
                  label: i.name,
                  value: id,
                }));
              },
              dependencies: [() => loadValuelistByGuid(p.valuelistGuid)],
            }
          : {}),
      })),
    ];
  }

  function loadProjectPage({
    moduleId,
    opt,
    columns,
    incPropIds = [],
    incLabelIds = [],
    ids = [],
  }: LoadProjectPageOpts): Promise<{
    count: number;
    entries: Array<Project>;
  }> {
    const query = filterQuery(opt, columns);

    query.append('moduleid', String(moduleId));

    incPropIds.forEach((id) => {
      query.append('incPropIds', String(id));
    });

    incLabelIds.forEach((id) => {
      query.append('incLabelIds', String(id));
    });

    ids.forEach((id) => {
      query.append('ids', String(id));
    });

    return api.get(`v2/projects?${query.toString()}`).then(({ data }) => {
      return {
        entries: data.map((p: any) => mapProjectTable(p)),
        count: data[0]?.TotalRows || 0,
      };
    });
  }

  function loadValueListCount(prop: string, moduleId: Modules, opt: Opt, columns: ProjectColumn[]) {
    const column = columns.find((c) => c.filterKey === prop);
    const iOpt = {
      ...opt,
      filters: opt.filters.filter((i) => i.columnId !== column?.id),
    };
    const query = filterQuery(iOpt, columns);

    query.append('moduleid', String(moduleId));

    return api
      .get(`v2/projects/propertyvaluecount/${prop}?${query.toString()}`)
      .then(({ data }) => {
        return data;
      })
      .catch(genericApiErrorHandler);
  }

  function loadProjectProperties(projectId: number): Promise<Map<number, ProjectProperty>> {
    return api
      .get(`v1/projects/${projectId}/properties`)
      .then(({ data }) => new Map(data.map((p: any) => [p.Id, mapProjectProperty(p)])))
      .catch(genericApiErrorHandler);
  }

  function loadProperties(): Promise<Map<number, { label: string; dataType: string }>> {
    const guids = [
      'd0364ca8-37ed-44a2-8614-d37600f516f2', // Planlagt start
      'f8333a3e-edc4-4659-bc8a-46da9a4ba407', // Planlagt ferdig
      'aa54bcdb-aa24-44b1-a015-d8987501ec40', // Planlagt tidsbruk
      '80cb0a54-b031-4322-a362-da01c6565f98', // Antall montører
    ];
    const queries = createQueryParams(new Map([['propertyGuids', guids]]));

    return api
      .get(`properties?${queries}`)
      .then(({ data }) => {
        return new Map(
          data.map((p: any) => [
            p.Id,
            {
              label: p.ShortName,
              dataType: p.DataType,
            },
          ]),
        );
      })
      .catch(genericApiErrorHandler);
  }

  return {
    projects,
    loadProjectById,
    loadProjectsByUser,
    updateProject,

    getProjectColumns,
    loadProjectPage,
    loadProjectProperties,
    loadValueListCount,

    projectTypes,
    loadProjectTypes,
    loadProperties,

    loadStatuses,
    updateStatus,
  };
});
