import { debounce } from 'lodash-es';

export default {
  name: 'DfInputSearch',

  props: {
    modelValue: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: 'Søk', // "Søk på X, X"
    },

    disabled: Boolean,

    elevate: Boolean,

    dropdownItem: Boolean, // Adds dropdown-item style when true

    inMenu: String,

    autofocus: Boolean,

    debounce: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['update:modelValue', 'input', 'clear'],

  data() {
    return {
      value: this.modelValue,
      debouncedEmit: debounce(this.emit, 700),
    };
  },

  watch: {
    modelValue(newValue) {
      this.value = newValue;
    },
  },

  computed: {
    placeholderCheck() {
      return !this.value && !this.disabled;
    },

    isVisible() {
      if (!this.dropdownItem) return true;

      if (this.inMenu) {
        return this.$parent.menu === this.inMenu;
      }

      else {
        return this.$parent.menu === '';
      }
    },
  },

  methods: {
    input() {
      this.emitters(this.value);
    },

    clear() {
      // Skip debounce on clear
      this.emit('');
      this.$emit('clear');
    },

    focus() {
      this.$refs.input?.focus();
    },

    emit(value) {
      this.$emit('update:modelValue', value);
      this.$emit('input', value);
    },

    emitters(value) {
      if (this.debounce) {
        this.debouncedEmit(value);
      }

      else {
        this.emit(value);
      }
    },
  },

  mounted() {
    if (this.autofocus) {
      this.focus();
    }
  },
};
