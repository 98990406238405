<template>
  <router-link
    v-if="isVisible && to"
    :to="to"
    class="item"
    :class="{ 'item--disabled': disabled, 'item--pad-start': padStart }"
    v-on:click.native="click"
    role="menuitem"
    ref="item"
    tabindex="0"
  >
    <slot></slot>
    <slot name="icon"></slot>
  </router-link>

  <a
    v-else-if="isVisible && href"
    :href="href"
    :target="target"
    class="item"
    :class="{ 'item--disabled': disabled, 'item--pad-start': padStart }"
    v-on:click="click"
    role="menuitem"
    ref="item"
    tabindex="0"
  >
    <slot></slot>
    <slot name="icon"></slot>
  </a>

  <!-- <button> smaker ikke så godt som det koster (spesialregler pga. grid) ... -->
  <div
    v-else-if="isVisible"
    class="item"
    :class="{
      'item--disabled': disabled,
      'item--to-menu': toMenu,
      'item--pad-start': padStart,
    }"
    v-on:click="click"
    role="menuitem"
    v-on:keyup.enter="click"
    ref="item"
    tabindex="0"
  >
    <slot></slot>
    <slot name="icon">
      <df-icon v-if="toMenu" code="f054" />
    </slot>
  </div>
</template>

<script src="./DfDropdown2Item.ts" lang="ts"></script>
<style  src="./DfDropdown2Item.css" scoped></style>