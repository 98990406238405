<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue';
import { Feedback, FeedbackType } from './DfAlertUtils';
import { isInViewport } from '/@utilities/general';

const props = withDefaults(
  defineProps<{
    modelValue?: Feedback;

    show?: boolean;
    variant?: number;
    persistent?: boolean;
    fixed?: boolean;
  }>(),
  {
    modelValue: () => ({
      title: '',
      message: '',
      type: FeedbackType.Info,
      show: false,
    }),
  },
);

const emit = defineEmits(['update:modelValue', 'dismissed']);

const alertRef = ref<HTMLElement | null>(null);

watch(
  () => props.modelValue.show,
  (newVal) => {
    if (!newVal || props.persistent) return;

    nextTick(() => {
      const element = alertRef.value;

      if (!element || isInViewport(element)) return;

      element.scrollIntoView();
      window.scrollBy(0, -70);
    });
  },
);

const pFeedback = computed(() => props.modelValue);

function close() {
  pFeedback.value.show = false;

  emit('update:modelValue', props.modelValue);
  emit('dismissed');
}

if (props.show) {
  pFeedback.value.show = props.show;
}

if (props.variant) {
  pFeedback.value.type = props.variant;
}
</script>

<template>
  <div
    v-if="modelValue.show || persistent"
    class="df-alert"
    :class="{
      'df-alert--success': [modelValue.type, variant].includes(FeedbackType.Success),
      'df-alert--warning': [modelValue.type, variant].includes(FeedbackType.Warning),
      'df-alert--danger': [modelValue.type, variant].includes(FeedbackType.Danger),
      'df-alert--info': [modelValue.type, variant].includes(FeedbackType.Info),
      'df-alert--icon': $slots.icon,
      'df-alert--fixed': fixed,
    }"
    ref="alertRef"
  >
    <div class="df-alert__icon" v-if="$slots.icon">
      <slot name="icon"></slot>
    </div>

    <div class="text">
      <div class="df-alert__title" v-if="modelValue.title">
        {{ modelValue.title }}
      </div>

      <div class="df-alert__text">
        {{ modelValue.message }}
        <slot></slot>
      </div>
    </div>

    <df-button
      v-on:click="close()"
      v-if="!persistent"
      :success="[modelValue.type, variant].includes(FeedbackType.Success)"
      :warning="[modelValue.type, variant].includes(FeedbackType.Success)"
      :danger="[modelValue.type, variant].includes(FeedbackType.Danger)"
    >
      <template v-slot:icon>
        <df-icon code="f00d" />
      </template>
    </df-button>

    <div class="df-alert__footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<style scoped>
.df-alert {
  background-color: var(--color-cardbg);
  border-radius: var(--radius-sm);
  padding: var(--gap-md);
  display: grid;
  grid-template-columns: 1fr max-content;
  box-shadow: var(--shadow-md);
  gap: var(--gap-sm);
  align-items: start;
  background-color: var(--color-info_bg);
  color: var(--color-info_text);
}

.df-alert--icon {
  grid-template-columns: max-content 1fr max-content;
}

.df-alert--fixed {
  position: fixed;
  top: var(--gap-md);
  left: 50%;
  transform: translate(-50%);
  max-width: 100%;
  width: 95%;
  z-index: 99;
}

@media (min-width: 900px) {
  .df-alert--fixed {
    max-width: 500px;
  }
}

.df-alert__title {
  font-weight: 500;
  font-size: 1.25rem;
}

.df-alert__text {
  font-weight: 500;
  align-self: center;
}

.df-alert--info {
  background-color: var(--color-info_bg);
  color: var(--color-info_text);
}

.df-alert--success {
  background-color: var(--color-success_bg);
  color: var(--color-success_text);
}

.df-alert--warning {
  background-color: var(--color-warn_bg);
  color: var(--color-warn_text);
}

.df-alert--danger {
  background-color: var(--color-danger_bg);
  color: var(--color-danger_text);
}

.df-alert__footer {
  grid-column: span 2;
}

.text {
  align-self: center;
}
</style>
