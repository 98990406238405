import { startOfISOWeek, setISOWeek, setISOWeekYear, addDays } from 'date-fns';

const pad = (i: number) => (String(i).length > 1 ? i : `0${i}`);

export function dateToString(date: Date) {
  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1);
  const year = date.getFullYear();

  return [year, month, day].join('-');
}

export function formatHours(time: number) {
  const [hours, min] = time.toString().split('.');
  const x = hours !== '0' ? hours + 't' : '';
  const y = min ? '30m' : '';
  return `${x} ${y}`;
}

export function getDateOfWeek(year: number, week: number) {
  let date = new Date();
  date = setISOWeekYear(date, year);
  date = setISOWeek(date, week);
  return startOfISOWeek(date);
}

export function getTimeFromDate(date) {
  if (!date) return date;
  const d = new Date(date);
  return [d.getHours(), d.getMinutes()].map((n) => pad(n)).join(':');
}

export const days = ['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag', 'Søndag'];

export const months = [
  'Januar',
  'Februar',
  'Mars',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Desember',
];

export function formatDateTime(time: string | null | undefined, date: Date) {
  if (!time) return null;
  return `${[date.getFullYear(), pad(date.getMonth() + 1), pad(date.getDate())].join('-')}T${time}`;
}
// returns array of dates in ISO week
export function getWeekDays(year: number, week: number) {
  const start = startOfISOWeek(setISOWeek(setISOWeekYear(new Date(), year), week));
  let weekDates = [start];

  for (let i = 1; i < 7; i++) {
    weekDates.push(new Date(addDays(start, i)));
  }

  return weekDates;
}

export function getDiffInMs(start: string, end: string) {
  const [startHour, startMin] = start.split(':').map(Number);
  const [endHour, endMin] = end.split(':').map(Number);
  const startMs = startHour * 60 * 60 * 1000 + startMin * 60 * 1000;
  const endMs = endHour * 60 * 60 * 1000 + endMin * 60 * 1000;
  return endMs - startMs;
}

export function formatMsToNum(ms: number): number {
  const hours = Math.floor(ms / (60 * 60 * 1000));
  const minutes = Math.floor((ms - hours * 60 * 60 * 1000) / (60 * 1000));
  const num = Number(`${hours}.${minutes}`);
  const num2 = Math.round(Number(num) / 0.5) * 0.5;
  return num2;
}

export function setTime(date: Date, end = false) {
  end ? date.setHours(22, 59, 59) : date.setHours(0, 0, 0);
  return date;
}
