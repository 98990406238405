import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DfDropdownItem',

  props: {
    toMenu: {
      type: String,
    },

    inMenu: {
      type: String,
    },

    /** Padding to align with checkbox and radio dropdown-item variants */
    padStart: Boolean,

    disabled: {
      type: Boolean,
      default: false,
    },

    noClose: {
      type: Boolean,
      default: false,
    },

    to: {
      type: String,
    },

    href: {
      type: String,
    },

    target: {
      type: String,
    },
  },

  data() {
    return {};
  },

  emits: ['click'],

  computed: {
    isVisible() {
      if (this.inMenu) {
        return this.$parent.menu === this.inMenu;
      }

      else {
        return this.$parent.menu === '';
      }
    }
  },

  methods: {
    click() {
      if (this.toMenu) {
        this.$parent.openMenu(this.toMenu);
      }

      else if (!this.noClose) {
        this.$parent.close();
      }

      this.$emit('click');
    },
  },

  created() {},
});
