import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DfSelect',

  props: {
    label: {
      type: String,
      default: '',
    },

    elevate: {
      type: Boolean,
      default: false,
    },

    primary: {
      type: Boolean,
    },

    active: {
      type: Boolean,
    },

    danger: {
      type: Boolean,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    grid: {
      type: Number,
    },

    noCaret: Boolean,
  },

  data() {
    return {
      observer: null,
      isOpen: false,
      menu: '',
      pushX: 0,
      pushY: 0,
      top: 0,
      right: 0,
      currentFocus: -1,
    };
  },

  emits: ['menu-change', 'close', 'open'],

  watch: {
    menu(menu) {
      this.$emit('menu-change', menu);
    },
  },

  methods: {
    toggle() {
      if (this.disabled) return;
      this.isOpen ? this.close() : this.open();
    },

    close() {
      this.observer.disconnect();

      this.isOpen = false;
      this.menu = '';
      this.pushX = 0;
      this.pushY = 0;
      this.top = 0;
      this.right = 0;

      this.$emit('close');

      removeEventListener('keyup', this.events, true);
    },

    open() {
      const { top, right } = this.$el.getBoundingClientRect();

      this.observer.observe(this.$el, { childList: true, subtree: true });

      this.isOpen = true;
      this.top = top;
      this.right = document.body.clientWidth - right;

      this.$emit('open');

      addEventListener('keyup', this.events, true);
  },

    openMenu(menu) {
      this.menu = menu;
    },

    events(event) {
      switch (event.key) {
        case 'Escape':
          this.currentFocus = 0;
          this.close();
          break;
        case 'ArrowDown': {
          const nodeList = [...<Array<HTMLElement>>this.$refs.menu.childNodes].filter((node: HTMLElement) => node.className === 'item')
          this.currentFocus = this.currentFocus === nodeList.length - 1 ? this.currentFocus : this.currentFocus + 1;
          this.$nextTick(() => {
            nodeList[this.currentFocus].focus();
          });
          break;
        }
        case 'ArrowUp': {
          const nodeList = [...<Array<HTMLElement>>this.$refs.menu.childNodes].filter((node) => node.className === 'item')
          this.currentFocus = this.currentFocus <= 0 ? 0 : this.currentFocus - 1;
          this.$nextTick(() => {
            nodeList[this.currentFocus].focus();
          });
          break;
        }
      }
    },
  },

  created() {
    this.observer = new MutationObserver((mutations) => {
      if (this.isOpen) {
        const rect = this.$refs.menu.getBoundingClientRect();
        const top = rect.top - this.pushY;
        const bottom = rect.bottom - this.pushY;
        const left = rect.left - this.pushX;
        const right = rect.right - this.pushX;
        const margin = 8; // Must match CSS max-width and max-height margins

        if (top < 0) {
          this.pushY = top * -1 + margin;
        }

        else if (bottom > innerHeight) {
          this.pushY = innerHeight - bottom - margin;
        }

        else {
          this.pushY = 0;
        }

        if (left < 0) {
          this.pushX = left * -1 + margin;
        }

        else if (right > innerWidth) {
          this.pushX = innerWidth - right - margin;
        }

        else {
          this.pushX = 0;
        }
      }
    })
  },
});