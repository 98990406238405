<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useNotes } from '/@stores/timer-notes';
import { date, time } from '/@utilities/intl';
import { toast } from '/@utilities/toast';
import { useTimerStore } from '/@stores/timer';
import { getTimeFromDate } from '/@utilities/date';
import { RegistrationStatusIds, Roles } from '/@types/ids';
import { awaitUser, useUserStore } from '/@stores/user';
import { hasRole } from '/@utilities/user';
import { maskHours } from '/@utilities/registrations';
import { storeToRefs } from 'pinia';

const route = useRoute();
const router = useRouter();

const notes = useNotes();

const timerStore = useTimerStore();
const { user } = storeToRefs(useUserStore());

const loading = ref(false);
const resolvingNote = ref(new Set());

const totalProjectHours = ref(0);

const registrationId = Number(route.params.registrationId);
const registration = computed(() => timerStore.registrations.get(registrationId));
const registrationNotes = computed(
  () => new Map([...notes.notes].filter(([id, note]) => note.registrationId === registrationId)),
);

const properties = computed(() => {
  const r = registration.value;
  if (!r) return [];

  const arr = [
    {
      label: 'Prosjekt',
      value: r.projectName,
      wide: true,
    },
    {
      label: 'Artikkel',
      value: r.articleName,
      wide: true,
    },
    ...(r.activityName
      ? [
          {
            label: 'Aktivitet',
            value: r.activityName,
            wide: true,
          },
        ]
      : []),
    {
      label: r.isExpense ? 'Antall' : 'Timer',
      value: maskHours(r.hours),
    },
    {
      label: 'Dato',
      value: r ? date(r.date, { capitalize: true }) : null,
    },
    {
      label: 'Status',
      value: r.statusName,
      wide: true,
    },
    {
      label: 'Kommentar',
      value: r.description,
      wide: true,
    },
    ...(r.modifiedAt
      ? [
          {
            label: 'Endret av',
            value: r?.modifiedBy,
          },
          {
            label: 'Endret dato',
            value: r ? date(r.modifiedAt, { capitalize: true }) : null,
          },
        ]
      : []),
    ...(r.approvedAt
      ? [
          {
            label: 'Godtatt av',
            value: r?.approvedBy,
          },
          {
            label: 'Godtatt dato',
            value: r ? date(r.approvedAt, { capitalize: true }) : null,
          },
        ]
      : []),
    ...(r.fromDateTimeOffset
      ? [
          {
            label: 'Fra',
            value: getTimeFromDate(r?.fromDateTimeOffset),
          },
          {
            label: 'Til',
            value: getTimeFromDate(r?.toDateTimeOffset),
          },
        ]
      : []),
  ];

  return arr;
});

const userStore = useUserStore();

const isRegistrationOwner = computed(() => registration.value?.userId === userStore.user.id);

const canBeCorrected = computed(
  () => registration.value?.statusId >= RegistrationStatusIds.Approved,
);

const canBeEdited = computed(() => {
  if (!registration.value?.registrationLocked) return true;
  const caseworkerPerm = !registration.value.isLocked || isCaseworker.value;
  return caseworkerPerm;
});

const noteForm = ref({
  text: null,
  sendEmail: true,
  creating: false,
  rejectRegistration: false,
  sendFeedback: false,
});

function sendNote() {
  noteForm.value.creating = true;

  const newStatusId = noteForm.value.rejectRegistration
    ? RegistrationStatusIds.Rejected
    : noteForm.value.sendFeedback
    ? RegistrationStatusIds.FeedbackSent
    : null;

  return Promise.all([
    notes
      .createRegistrationNote(
        registrationId,
        noteForm.value.text,
        isRegistrationOwner.value ? false : true,
        noteForm.value.sendEmail,
      )
      .then(() => {
        notes.loadRegistrationNotes(registrationId);
        noteForm.value.text = null;
        noteForm.value.creating = false;
        toast('Notat opprettet');
      }),
    ...(newStatusId
      ? [
          timerStore
            .changeRegistrationStatus(registrationId, newStatusId)
            .then(() =>
              Promise.all([
                timerStore.loadRegistration(registrationId),
                timerStore.loadRejectedRegistrations(user.value.id),
              ]),
            ),
        ]
      : []),
  ]);
}

async function resolve(noteId: number) {
  resolvingNote.value.add(noteId);
  await notes.resolveRegistrationNote(registrationId, noteId);
  resolvingNote.value.delete(noteId);
  toast('Notat løst');
}

async function load() {
  loading.value = true;
  await awaitUser;
  await Promise.all([
    timerStore.loadRegistration(registrationId),
    notes.loadRegistrationNotes(registrationId),
  ]);
  if (registration.value) {
    totalProjectHours.value = await timerStore.loadTotalProjectHours(registration.value.projectId);
  }
  loading.value = false;
}

load();

const isCaseworker = computed(() => hasRole(userStore.user, Roles.Saksbehandler));

function navToReg(copy = false) {
  return router.push({
    path: `/timer/registration/${registrationId}/edit`,
    query: { createCopy: `${copy}` },
  });
}
</script>

<template>
  <div class="page">
    <df-header :back="true">
      <template v-slot:default>Registrering</template>

      <template v-slot:actions v-if="registration">
        <df-button :to="`/timer/registration/${registrationId}/edit`" v-if="canBeEdited">
          <template v-slot:default>Rediger</template>

          <template v-slot:icon>
            <df-icon code="f304" />
          </template>
        </df-button>
      </template>
    </df-header>

    <df-loading v-if="loading" />

    <section v-if="registration" class="registration-item">
      <dl>
        <div
          v-for="property in properties"
          :key="property.label"
          class="entry"
          :class="{ wide: property.wide }"
        >
          <dt>{{ property.label }}</dt>

          <dd>
            <template v-if="property.list">
              <template v-if="property.list.length > 0">
                <div class="badge" v-for="(value, index) in property.list" :key="index">
                  {{ value }}
                </div>
              </template>

              <template v-else>-</template>
            </template>

            <template v-else>{{ property.value || '-' }}</template>
          </dd>
        </div>
      </dl>

      <div class="actions">
        <df-button v-on:click="navToReg(true)" stack>
          Kopiér
          <template v-slot:icon>
            <df-icon code="f24d" />
          </template>
        </df-button>

        <df-button v-if="canBeCorrected" v-on:click="navToReg()" stack>
          Korrigér
          <template v-slot:icon>
            <df-icon code="f333" />
          </template>
        </df-button>

        <df-button
          :href="`https://webapp.dokflyt.no/energi/projects/${registration.projectId}`"
          stack
        >
          Gå til prosjekt
          <template v-slot:icon>
            <df-icon code="f35d" />
          </template>
        </df-button>
      </div>
    </section>

    <section v-if="registration" class="registration-item">
      <h2>Prosjektinformasjon</h2>
      <dl>
        <div class="entry">
          <dt>Timer registrert totalt</dt>
          <dd>{{ maskHours(totalProjectHours) }}</dd>
        </div>
      </dl>
    </section>

    <section class="notes">
      <h2>Dialog</h2>

      <form class="notes-form" v-on:submit.prevent="sendNote()">
        <df-input v-model="noteForm.text" no-delay />

        <df-button type="submit" :disabled="noteForm.creating || !noteForm.text">
          Send

          <template v-slot:icon>
            <df-loading v-if="noteForm.creating" no-float />
            <df-icon v-else code="f1d8" />
          </template>
        </df-button>

        <df-checkbox class="wide" v-model="noteForm.sendEmail" no-border>
          Send varsel på e-post
        </df-checkbox>

        <df-checkbox
          v-if="
            registration?.statusId !== RegistrationStatusIds.Rejected &&
            hasRole(userStore.user, Roles.Saksbehandler)
          "
          class="wide"
          v-model="noteForm.rejectRegistration"
          no-border
        >
          Avvis registrering
        </df-checkbox>

        <df-checkbox
          v-if="registration?.statusId === RegistrationStatusIds.Rejected"
          class="wide"
          v-model="noteForm.sendFeedback"
          no-border
        >
          Tilbakemelding sendt
        </df-checkbox>
      </form>

      <div class="notes-entries" v-if="registrationNotes.size > 0">
        <div v-for="[id, note] in registrationNotes" :key="id" class="note">
          <header class="note-header">
            <div>
              <div style="font-weight: 600">{{ note.createdByUser }}</div>
              <div>{{ date(note.createdAt) }} {{ time(note.createdAt) }}</div>
            </div>

            <df-button
              v-if="!note.resolvedAt && isRegistrationOwner"
              v-on:click="resolve(id)"
              :disabled="resolvingNote.has(id)"
            >
              Løs
              <template v-slot:icon v-if="resolvingNote.has(id)">
                <df-loading no-float />
              </template>
            </df-button>

            <div class="badge" v-else-if="!!note.resolvedAt">
              <df-icon code="f00c" />
              Løst
            </div>
          </header>

          <div>{{ note.text }}</div>
        </div>
      </div>

      <div class="placeholder" v-else>Ingen notater</div>
    </section>
  </div>
</template>

<style scoped>
dl {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: var(--color-cardbg);
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-md);
}

.wide {
  grid-column: 1 / -1;
}

.entry {
  padding: var(--gap-md);
}

.entry:not(:first-child) {
  border-top: 1px solid var(--color-divider);
}

dt {
  font-size: 0.8rem;
  font-weight: 600;
}

dd {
  display: flex;
  gap: var(--gap-sm);

  @media (max-width: 1000px) {
    font-size: 0.85rem;
  }
}

.notes {
  display: grid;
  gap: var(--gap-md);
}

.notes-entries {
  display: grid;
  background-color: var(--color-cardbg);
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-md);
}

.notes-entries > *:not(:last-child) {
  border-bottom: 1px solid var(--color-border);
}

.note {
  padding: var(--gap-md);
}

.note-header {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: start;
  gap: var(--gap-md);
  font-size: 0.9rem;
}

.notes-form {
  display: grid;
  gap: var(--gap-md);
  grid-template-columns: 1fr max-content;
  background-color: var(--color-cardbg);
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-md);
  padding: var(--gap-md);
}

.actions {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: var(--gap-md);
}

.registration-item {
  display: grid;
  gap: var(--gap-md);
}
</style>
