import { computed, ref, watch } from 'vue';
import { search } from '/@utilities/general';
import { debounce } from 'lodash-es';

import { ResourceEvent } from '/@types/resources';

import {
  loadResourceEventsByDate,
  loadResourceEventsByDateForCurrentUser,
  loadResourceFilter,
} from '/@stores/resources';


async function getUsersByGroup(groupId: number): Promise<Array<string>> {
  const filter = await loadResourceFilter();

  if (!filter) return [];

  const users = filter
    .filter((group) => group.id === groupId)
    .flatMap((group) => group.resources.map((resource) => resource.guid));

  return users;
}

export function useResourceEvents({ byUser = false }) {
  const loading = ref(0);
  const selectedDate = ref<Date | null>(null);
  const dateRange = {
    start: new Date(),
    end: new Date(),
  };
  const userSearch = ref(null);
  const groupSearch = ref(null);
  const selectedGroupUsers = ref<Array<string>>([]);

  watch(groupSearch, async (newGroupId) => {
    selectedGroupUsers.value = newGroupId 
      ? await getUsersByGroup(newGroupId)
      : [];
  });

  const allEvents = ref<Array<ResourceEvent>>([]);

  const events = computed(() => {
    return allEvents.value.filter((event) => {
      const userFilter = userSearch.value ? search(userSearch.value, event.resourceGuid) : true;

      const groupFilter = groupSearch.value
        ? selectedGroupUsers.value.includes(event.resourceGuid)
        : true;

      if (event.resourceType === 'group') return false;

      return userFilter && groupFilter;
    });
  });

  const dateChangeFn = ({ start, end }: { start: Date; end: Date }) => {
    selectedDate.value = start;

    if (
      dateRange.start.getTime() !== start.getTime() ||
      dateRange.end.getTime() !== end.getTime()
    ) {
      dateRange.start = start;
      dateRange.end = end;

      const endpoint = byUser ? loadResourceEventsByDateForCurrentUser : loadResourceEventsByDate;

      loading.value++;

      return endpoint(start, end)
        .then((data) => {
          allEvents.value = data;
        })
        .finally(() => {
          loading.value--;
        });
    }
  };

  const dateChange = debounce(dateChangeFn, 500);

  return { loading, selectedDate, events, groupSearch, selectedGroupUsers, userSearch, dateChange };
}
