<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import {
  loadResourceEventsByDate,
  loadResourceEventsByGuid,
  loadResourceFilter,
  loadResources,
} from '/@stores/resources';
import {
  DefaultWorkingHours,
  Resource,
  ResourceEvent,
  ResourceFilter,
  ScheduleResource,
} from '/@types/resources';
import DfCalendar from '/@elements/DfCalendar/DfCalendar.vue';
import DfCalendarEvent from '/@elements/DfCalendarEvent/DfCalendarEvent.vue';
import DfInputSearch from '/@elements/DfInputSearch/DfInputSearch.vue';
import EventDialog from './EventDialog/EventDialog.vue';
import ResourceDialog from './ResourceDialog/ResourceDialog.vue';
import CreateEvent from './CreateEvent/CreateEvent.vue';
import FilterItems from './FilterItems/FilterItems.vue';
import { awaitTenant, useTenantStore } from '/@stores/tenant';
import { Modules, ProjectProperties, StatusGroups, TenantFeatures } from '/@types/ids';
import { newListOpt, optFromLocalStorage, optToLocalStorage, FilterTools2 } from '/@utilities/opt';
import { add, intervalToDuration } from 'date-fns';
import { OptType } from '/@types/opt';
import { createEventForm, EventForm } from '/@utilities/events';
import { toast } from '/@utilities/toast';
import useEvents from '/@composables/useEvents';
import { errorText } from '/@utilities/api';
import { feedback, FeedbackType } from '/@elements/DfAlert/DfAlertUtils';
import { useProjectStore, ProjectDetails } from '/@features/project';
import { Project, ProjectColumn } from '/@features/project/project.types';
import { initDependencies } from '/@features/project/project.store';
import { useResourceEvents } from '../ResourceEventsPage/UseResourceEvents';
import { date } from '/@utilities/intl';
import { setTime } from '/@utilities/date';
import { debounce } from 'lodash-es';
import { useUserStore } from '/@stores/user';
import { useOutlook } from '/@composables/useOutlook';

const tenantStore = useTenantStore();
const projectStore = useProjectStore();

const projectOptDefault = {
  ...newListOpt({
    filters: [
      {
        columnId: 'statusGroupId',
        values: [StatusGroups.Aktive, StatusGroups.Prosjektering],
      },
    ],
  }),
  sortBy: 'id',
  limit: 20,
};

const projectOpt = ref({
  ...optFromLocalStorage(OptType.List, 'scheduler/opt', projectOptDefault),
});

watch(
  projectOpt,
  (value) => {
    searchProjects();
    optToLocalStorage('scheduler/opt', value);
  },
  { deep: true },
);

const showProjectInfo = ref(false);
const projects = ref<Project[]>([]);
const projectsCount = ref(0);
const adminProjects = ref<Project[]>([]);
const dateRange = { start: new Date(), end: new Date() };
const users = ref(<Array<Resource>>[]);
const resources = ref<Array<Resource>>([]);

const eventForm = ref<EventForm | null>(null);

watch(eventForm, (value) => {
  if (value == null) {
    showProjectInfo.value = false;
  }
});

const showCreateEvent = ref(false);

const defaultWorkingHours = ref<DefaultWorkingHours | void>({
  startTime: {
    hours: 8,
    minutes: 0,
  },
  endTime: {
    hours: 16,
    minutes: 0,
  },
});

const resourceFilters = ref<Array<ResourceFilter>>([]);

const { groupSearch, selectedGroupUsers } = useResourceEvents({ byUser: false });

const activeFilterId = ref<null | number>(JSON.parse(localStorage.getItem('scheduler/filter-id')));
watch(activeFilterId, (value) => {
  localStorage.setItem('scheduler/filter-id', JSON.stringify(value));
  if (value == null) return;
  reloadResources();
});

const loadingSearch = ref(false);
const loadingEvent = ref(new Set());
const loading = ref(0);
const noFilters = ref(false);

const activeFilter = computed(() =>
  resourceFilters.value.find(({ id }) => id === activeFilterId.value),
);
const activeResources = computed(() =>
  resources.value.filter(({ filters }) => filters.some(({ id }) => id === activeFilterId.value)),
);

const mappedGroups = computed(
  () => new Map(resourceFilters.value.map((res) => [res.id, { name: res.name }])),
);

const mappedUsers = computed(() => {
  if (selectedGroupUsers.value.length > 0) {
    return new Map(
      users.value
        .filter((res) => selectedGroupUsers.value.includes(res.guid))
        .map((res) => [res.guid, { name: res.name }]),
    );
  }

  return new Map(users.value.map((u) => [u.guid, u]));
});

const events = useEvents();

// NOTE: FullCalendar will always trigger this on first render as well
async function changeDate({ start, end }: { start: Date; end: Date }) {
  if (
    activeFilterId.value != null &&
    (dateRange.start.getTime() !== start.getTime() || dateRange.end.getTime() !== end.getTime())
  ) {
    dateRange.start = start;
    dateRange.end = end;

    return loadEvents();
  }
}

const listNew = ref<ProjectColumn[]>([]);

const propBadges = [
  ProjectProperties.PlanlagtStart,
  ProjectProperties.PlanlagtFerdig,
  ProjectProperties.RessursTidsbruk,
  ProjectProperties.RessursMontør,
];
const dynamicProperties = ref<Map<number, { label: string; dataType: string }>>(new Map());

// sorting search results by start date
function sortEntries(entries: Project[]): Project[] {
  return entries.toSorted((a, b) => {
    const dateA = new Date(a.properties.get(ProjectProperties.PlanlagtStart)?.value);
    const dateB = new Date(b.properties.get(ProjectProperties.PlanlagtStart)?.value);
    return dateA.getTime() - dateB.getTime();
  });
}

function mapProperty(dataType: string, value: string) {
  switch (dataType) {
    case 'datetime':
      return date(value, { weekday: false });
    default:
      return value;
  }
}

function searchProjects(offset = 0) {
  loadingSearch.value = true;
  projectStore
    .loadProjectPage({
      moduleId: Modules.Ressurs,
      opt: offset === 0 ? projectOpt.value : { ...projectOpt.value, offset },
      columns: listNew.value,
      incPropIds: propBadges,
    })
    .then((data) => {
      const entries = offset === 0 ? data.entries : projects.value.concat(data.entries);
      projects.value = sortEntries(entries);
      projectsCount.value = data.count;
    })
    .finally(() => {
      loadingSearch.value = false;
    });
}

function getEvent(eventGuid: string) {
  if (events.events.value.some(({ guid }) => guid === eventGuid)) return Promise.resolve();

  loadingEvent.value.add(eventGuid);
  return loadResourceEventsByGuid(eventGuid)
    .then((event) => {
      if (!event) return;
      events.events.value.push(event);
    })
    .finally(() => {
      loadingEvent.value.delete(eventGuid);
    });
}

const outlook = useOutlook();

function loadEvents() {
  loading.value++;

  return loadResourceEventsByDate(dateRange.start, dateRange.end, activeFilterId.value)
    .then((res) => {
      events.events.value = res || [];
      // endpoint cannot exceed 104 days
      if (!tenantStore.tenant?.tenantFeatures.includes(TenantFeatures.OutlookIntegration)) return;
      if ((intervalToDuration(dateRange)?.months || 0) > 3) return;
      return outlook.loadEvents(activeFilterId.value, dateRange.start, dateRange.end).then(() => {
        [...outlook.events.value.values()].forEach((e) => {
          events.events.value.push(e);
        });
      });
    })
    .finally(() => {
      loading.value--;
    });
}

const selectedResource = ref<ScheduleResource | null>(null);

function setSelectedResource(event: ResourceEvent) {
  return createEventForm(
    {
      toDate: add(event.date, { hours: 8 }),
      fromDate: event.date,
      resourceGuid: event.resource.guid,
      resourceType: event.resource.type,
      userName: event.resource.name,
    },
    resources.value,
    defaultWorkingHours.value,
  ).then((res) => {
    eventForm.value = res;
    selectedResource.value = res;
    showCreateEvent.value = true;
  });
}

const selectedUserId = ref(null);

function createFromSelectedUser() {
  if (!selectedUserId) return;
  const u = users.value.find(({ guid }) => guid === selectedUserId.value);
  if (!u) return;

  createEventForm(
    {
      resourceGuid: u.guid,
      resourceType: u.type,
      userName: u.name,
    },
    resources.value,
    defaultWorkingHours.value,
  ).then((res) => {
    selectedResource.value = res;
    showCreateEvent.value = true;
  });
}

const saving = ref(false);

const feedbackObject = ref({});

let avtaleToast;
let count = 0;

function submitWrapper(promise: Promise<any>, create = false) {
  loading.value++;
  saving.value = true;

  return promise
    .then(() => {
      const text = `Avtale ${create ? 'opprettet' : 'oppdatert'} (${++count})`;
      !avtaleToast ? (avtaleToast = toast(text)) : avtaleToast.text(text);
    })
    .catch((error) => {
      feedbackObject.value = feedback({
        title: `Avtalen ble ikke ${create ? 'opprettet' : 'oppdatert'}`,
        message: error.isCustom ? error.message : errorText(),
      });
    })
    .finally(() => {
      loading.value--;
      saving.value = false;
    });
}

async function reloadResources() {
  loading.value++;

  // This if prevents it loading if we haven't gotten dates from fullcalender
  if (dateRange.start.getTime() !== dateRange.end.getTime()) {
    await loadEvents();
  }

  await loadResources({ filterId: activeFilterId.value }).then(
    (data) =>
      (resources.value = (data || []).sort((a, b) => {
        if (a.type === 'group' && b.type === 'user') return 1;
        if (b.type === 'group' && a.type === 'user') return -1;

        const aIndex =
          a.sortIndex ?? a.filters.find(({ id }) => id === activeFilterId.value)?.sortIndex ?? 0;
        const bIndex =
          b.sortIndex ?? b.filters.find(({ id }) => id === activeFilterId.value)?.sortIndex ?? 0;

        return aIndex - bIndex;
      })),
  );

  loading.value--;
}

function filterStatusList() {
  // gets status valuelist, filters dependencies
  projectStore
    .loadValueListCount('statusIds', Modules.Ressurs, projectOpt.value, listNew.value)
    .then((i) => {
      const statusCounts = new Set(i.filter((u) => u.ValueCount > 0).map((u) => Number(u.Value)));

      const statusIndex = listNew.value.findIndex(({ id }) => id === 'statusId');
      if (!statusIndex) return;
      const statusCol = listNew.value[statusIndex];

      listNew.value.splice(statusIndex, 1, {
        ...statusCol,
        _resolvedDependencies: [
          [...statusCol._resolvedDependencies[0]].filter(([id, d]) => statusCounts.has(id)),
        ],
      });
    });
}

function resetFilters() {
  projectOpt.value = { ...projectOptDefault };
}

const isMobile = computed(() => matchMedia('(max-width: 999.5px)').matches);

const selectedProjectId = ref<number | null>(null);

async function initialize() {
  await awaitTenant;

  defaultWorkingHours.value = tenantStore.getDefaultWorkingHours();

  tenantStore.loadCaseworkers();
  tenantStore.loadDepartments();

  projectStore.loadProperties().then((props) => {
    dynamicProperties.value = props;
  });

  projectStore
    .getProjectColumns({ moduleId: Modules.Ressurs, opt: projectOpt.value })
    .then((columns) => initDependencies(columns))
    .then((columns) => {
      listNew.value = columns;

      filterStatusList();
      searchProjects();

      // admin projects
      projectStore
        .loadProjectPage({
          moduleId: Modules.Admin,
          opt: newListOpt({
            filters: [
              { columnId: 'statusGroupId', values: [StatusGroups.Aktive] },
              { columnId: 'typeId', values: [11, 90] },
            ],
          }),
          columns: listNew.value,
        })
        .then((data) => (adminProjects.value = data.entries));
    });

  loadResourceFilter().then((res) => {
    if (!res) return;
    if (res.length === 0) {
      noFilters.value = true;
    } else {
      resourceFilters.value = res;
      // If current filter is null or doesnt exist, use first filter.
      if (res.every((filter) => filter.id !== activeFilterId.value)) {
        activeFilterId.value = res[0].id;
      }
    }
  });

  loadResources({ ungrouped: true }).then((data) => {
    users.value = data || [];
  });

  reloadResources();
}

initialize();

const user = useUserStore();

const propAccess = computed(() => [1, 2, 487].some((i) => user.user?.tenant.id === i));

function doSo(propId: number, type: 'lte' | 'gte', value: Date) {
  const val = value ? setTime(new Date(value), type === 'lte') : null;

  projectOpt.value.filters2 = val
    ? FilterTools2.set(projectOpt.value.filters2, propId, type, val.toISOString())
    : FilterTools2.clear(projectOpt.value.filters2, propId, type);
}

const debouncedDoSo = debounce(doSo, 500);
</script>

<template>
  <div class="schedule-page">
    <header class="header">
      <h1 class="title title-lg">Planlegg avtaler</h1>

      <df-loading v-if="loading > 0" />

      <df-alert v-model="feedbackObject" :variant="FeedbackType.Danger" fixed />
    </header>

    <template v-if="isMobile">
      <div class="mobile-list">
        <div class="mobile-list__select">
          <df-select3
            v-model="groupSearch"
            label="Velg gruppe"
            :entries="mappedGroups"
            display="name"
            elevate
          />

          <df-button v-if="groupSearch" v-on:click="groupSearch = null" elevate>
            <template v-slot:icon>
              <df-icon code="f00d" />
            </template>
          </df-button>
        </div>

        <div class="mobile-list__select">
          <df-select3
            v-model="selectedUserId"
            :entries="mappedUsers"
            label="Velg ressurs"
            display="name"
            elevate
          />

          <df-button v-if="selectedUserId" v-on:click="selectedUserId = null" elevate>
            <template v-slot:icon>
              <df-icon code="f00d" />
            </template>
          </df-button>
        </div>

        <df-button v-on:click="createFromSelectedUser()" :disabled="!selectedUserId">
          Opprett avtale
        </df-button>
      </div>
    </template>

    <template v-else>
      <div class="sidebar">
        <df-select class="schedule-page__filters" label="Gruppe" elevate>
          <template v-slot:text>
            {{ activeFilter?.name ?? 'Velg gruppe' }}
            <div class="badge" v-if="activeFilter?.type">
              {{ activeFilter.type }}
            </div>
          </template>

          <df-dropdown2-item
            v-for="filter in resourceFilters"
            :key="filter.id"
            v-on:click="activeFilterId = filter.id"
          >
            {{ filter.name }}
            <div class="badge" v-if="filter?.type">{{ filter.type }}</div>
            <div class="badge badge--info" v-if="filter?.isUserSpecific">Personlig</div>
          </df-dropdown2-item>
        </df-select>

        <div class="schedule-page__projects card">
          <div class="projects">
            <div class="sidebar-filters">
              <df-input-search placeholder="Søk" v-model="projectOpt.search" debounce />

              <df-button v-on:click="resetFilters()" style="width: calc(100% - 2px)">
                Nullstill søk
              </df-button>

              <details class="collapse" open>
                <summary class="collapse__title">Filtrering</summary>

                <div class="filter-items">
                  <FilterItems
                    :project-opt="projectOpt"
                    :filter-items="listNew"
                    v-on:update="projectOpt.filters = $event"
                  >
                    <template v-slot:col="{ col }">
                      <div
                        v-if="
                          [
                            ProjectProperties.PlanlagtStart,
                            ProjectProperties.PlanlagtFerdig,
                          ].includes(col.id) && propAccess
                        "
                        class="ig"
                      >
                        <div class="label">{{ col.label }}</div>

                        <div class="double-input">
                          <df-datepicker
                            :value="FilterTools2.get(projectOpt.filters2, col.id, 'gte')"
                            v-on:update="debouncedDoSo(col.id, 'gte', $event)"
                            placeholder="Fra"
                            hide-weekday
                          />

                          <df-datepicker
                            :value="FilterTools2.get(projectOpt.filters2, col.id, 'lte')"
                            v-on:update="debouncedDoSo(col.id, 'lte', $event)"
                            placeholder="Til"
                            hide-weekday
                          />
                        </div>
                      </div>
                    </template>
                  </FilterItems>
                </div>
              </details>
            </div>

            <div class="loading-icon" v-if="loadingSearch"></div>

            <details class="collapse">
              <summary class="collapse__title">Administrativt</summary>

              <df-calendar-event
                v-for="{ id, name, typeId, colorHex } in adminProjects"
                :key="id"
                :name="name"
                :data="{
                  projectId: id,
                  projectName: name,
                  projectTypeId: typeId,
                }"
              >
                <div class="projects__item">
                  <df-icon code="e411" solid />
                  {{ name }}
                  <df-icon v-if="colorHex" code="f111" solid :style="{ color: `#${colorHex}` }" />
                </div>
              </df-calendar-event>
            </details>

            <details class="collapse" open>
              <summary class="collapse__title">
                {{
                  projectOpt.filters.length <= 1 && projectOpt.search.length === 0
                    ? 'Aktive prosjekter'
                    : 'Søkeresultater'
                }}
              </summary>

              <df-calendar-event
                v-for="project in projects"
                :key="project.id"
                :name="project.name"
                :data="{
                  projectId: project.id,
                  projectName: project.name,
                  projectTypeId: project.typeId,
                }"
              >
                <div class="projects__item">
                  <df-icon code="e411" solid />

                  <div>
                    <div>{{ project.name }}</div>
                    <div class="badges">
                      <template
                        v-for="[id, prop] in [...project.properties].filter(([id, _]) =>
                          propBadges.includes(id),
                        )"
                      >
                        <div class="badge" v-if="prop.value">
                          <div>{{ dynamicProperties.get(id)?.label || '' }}</div>
                          <div>{{ mapProperty(prop.dataType, prop.value) }}</div>
                        </div>
                      </template>
                    </div>
                  </div>

                  <df-button v-on:click="selectedProjectId = project.id">
                    <template v-slot:icon><df-icon code="f05a" /></template>
                  </df-button>
                </div>
              </df-calendar-event>

              <div class="more-search" v-if="projectsCount > projects.length">
                <em> Søket ga {{ projectsCount - projects.length }} flere treff </em>
                <df-button v-on:click="searchProjects(projects.length)">
                  Last inn {{ projectOpt.limit }} til
                </df-button>
              </div>
            </details>
          </div>
        </div>
      </div>

      <div class="schedule-page__no-filters" v-if="noFilters">
        <p>For å se avtaler, må det først opprettes grupper</p>
        <df-button to="/resources/groups" elevate> Administrer grupper </df-button>
      </div>

      <df-calendar
        class="schedule-page__calendar"
        view="dfResourceWeekEaDay"
        buttons="dfResourceQuarter,dfResourceMonth,dfResourceWeekEaDay,dfResourceWeekEaHour"
        :events="events.events.value"
        :resources="activeResources"
        v-else
        v-on:date-change="changeDate($event)"
        v-on:date-click="setSelectedResource($event)"
        v-on:event-add="submitWrapper(events.fromDrop($event, true), true)"
        v-on:event-edit="submitWrapper(events.fromExisting($event), false)"
        v-on:event-active="
          eventForm =
            $event == null ? null : createEventForm($event, resources, defaultWorkingHours)
        "
      >
        <template v-slot:event="{ event, close, setActiveEvent }">
          <event-dialog
            :event="event"
            :close="close"
            :resources="resources"
            :set-active-event="setActiveEvent"
            :update="events.updateEventItems"
            :delete="events.deleteEventItem"
            :unlink="events.unlinkEventItem"
            v-on:new-event="
              getEvent(event.mainEventGuid).then(() =>
                setActiveEvent(
                  events.events.value.find(({ guid }) => event.mainEventGuid === guid),
                ),
              )
            "
          />
        </template>

        <template v-slot:resource="{ resource, close }">
          <resource-dialog
            :resource="resource"
            :resource-filters="resourceFilters"
            :close="close"
          />
        </template>
      </df-calendar>
    </template>

    <df-modal v-if="showCreateEvent && selectedResource">
      <create-event
        :selected-resource="selectedResource"
        :saving="saving"
        v-on:create="
          submitWrapper(events.fromForm($event, true), true).then(() => (showCreateEvent = false))
        "
        v-on:close="showCreateEvent = false"
      />
    </df-modal>

    <df-modal v-if="selectedProjectId">
      <ProjectDetails v-on:back="selectedProjectId = null" :project-id="selectedProjectId" />
    </df-modal>
  </div>
</template>

<style scoped>
.schedule-page {
  display: grid;
  gap: var(--gap-lg);
  padding: var(--gap-lg);

  @media (min-width: 1000px) {
    grid-template-columns: minmax(35ch, 1fr) 4fr;
    grid-template-rows: max-content minmax(0, 1fr);
    height: 100vh;
  }
}

.schedule-page__projects {
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr);
  gap: var(--gap-md);
  overflow: auto;
}

.schedule-page__calendar {
  z-index: 0;
}

.schedule-page__no-filters {
  place-self: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-md);
}

.projects {
  padding: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);

  & > * {
    flex: none;
  }
}

.projects__item {
  border-top: 1px solid var(--color-divider);
  padding: var(--gap-md) 0;
  padding-right: var(--gap-sm);
  cursor: default;
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr);
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: var(--gap-sm);
  align-items: center;
  background-color: var(--color-cardbg);
}

.more-search {
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
  padding: var(--gap-md);
}

.header {
  display: grid;
  grid-column: span 2;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  justify-content: space-between;
  align-items: center;
}

.sidebar {
  display: grid;
  gap: var(--gap-md);
  grid-template-rows: max-content 1fr;
  overflow: hidden;
  position: relative;
}

.sidebar-filters {
  display: grid;
  gap: var(--gap-md);
}

.filter-items {
  display: grid;
  gap: var(--gap-md);
  padding: var(--gap-md);
}

.mobile-list {
  display: grid;
  gap: var(--gap-md);
  grid-column: span 2;
}

.mobile-list__select {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-template-columns: 1fr;
  gap: var(--gap-md);
}

.badges {
  display: flex;
  gap: var(--gap-sm);
  flex-wrap: wrap;
}

.ig {
  display: grid;
  gap: var(--gap-sm);

  padding: var(--gap-md);
  border: 1px solid var(--color-border);
  border-radius: var(--radius-sm);

  & > .label {
    font-size: 0.8rem;
    font-weight: 500;
  }
}

.double-input {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-md);
}
</style>
<style src="./SchedulePagePrint.css"></style>
