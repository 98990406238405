<template>
  <div :class="{ 'dropdown-style': dropdownItem }" v-if="isVisible" role="menuitem" ref="item">
    <label
      class="input"
      :class="{
        'input--elevate': elevate,
      }"
    >
      <div class="input__icon">
        <df-icon code="f002" />
      </div>

      <input
        class="input__input"
        v-model="value"
        v-on:input="input()"
        ref="input"
        type="text"
        inputmode="search"
        :autofocus="autofocus"
      />

      <div
        v-if="placeholderCheck"
        class="input__placeholder"
      >
        {{ placeholder }}
      </div>

      <button
        class="input__clear"
        v-if="value"
        v-on:click="clear()"
        title="Tøm felt"
        type="button"
       >
        <df-icon code="f00d" />
      </button>
    </label>
  </div>
</template>

<script src="./DfInputSearch.ts" lang="ts"></script>
<style src="./DfInputSearch.css" scoped></style>
