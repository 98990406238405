<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { toast } from '/@utilities/toast';
import { Roles } from '/@types/ids';
import { hasRoles } from '/@utilities/user';
import { useUserStore } from '/@stores/user';
import { useTimerStore } from '/@stores/timer';
import { genericApiErrorHandler } from '/@utilities/api';
import { FeedbackType } from '/@elements/DfAlert/DfAlertUtils';
import { DefaultWorkingHours, TimepickerValues } from '/@types/resources';
import { saveDefaultWorkingHours } from '/@stores/resources';
import { awaitTenant, useTenantStore } from '/@stores/tenant';
import { Modules } from '/@types/ids';

const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const tenantStore = useTenantStore();
const { tenant } = storeToRefs(tenantStore);

const timerStore = useTimerStore();

const registrationKey = 'showWeekends';
const lsRegistration: string | null = localStorage.getItem(registrationKey);
const showWeekends = ref<boolean>(lsRegistration ? JSON.parse(lsRegistration) : true);

const resourceKey = 'resourceWeekends';
const lsResource: string | null = localStorage.getItem(resourceKey);
const resourceWeekends = ref<boolean>(lsResource ? JSON.parse(lsResource) : true);

function update(b: boolean, key: string) {
  localStorage.setItem(key, JSON.stringify(b));
}

const totalWorkingHours = ref<number>(7.5);

const defaultWorkingHours = ref<DefaultWorkingHours | void>({
  startTime: {
    hours: 8,
    minutes: 0,
  },
  endTime: {
    hours: 16,
    minutes: 0,
  },
});

const currentTenantId = computed(() => tenant.value?.id);

const hasTimer = computed(() => userStore.hasModule(Modules.Timer));

const timepickerValues = ref<TimepickerValues>(timePickerFormat(defaultWorkingHours.value));

watch(
  timepickerValues,
  (newValue) => {
    const { start, end } = newValue;
    const [startHoursString, startMinutesString] = start.split(':');
    const [endHoursString, endMinutesString] = end.split(':');

    defaultWorkingHours.value = {
      startTime: {
        hours: parseInt(startHoursString, 10),
        minutes: parseInt(startMinutesString, 10),
      },
      endTime: {
        hours: parseInt(endHoursString, 10),
        minutes: parseInt(endMinutesString, 10),
      },
    };
  },
  { deep: true },
);

function formatTime(hours: number, minutes: number) {
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

function timePickerFormat(workhours: DefaultWorkingHours | void) {
  if (!workhours) return { start: formatTime(8, 0), end: formatTime(16, 0) };

  return {
    start: formatTime(workhours.startTime.hours, workhours.startTime.minutes),
    end: formatTime(workhours.endTime.hours, workhours.endTime.minutes),
  };
}

function saveTotalWorkingHoursAction() {
  if (!currentTenantId.value) return toast('Feil: Kunne ikke lagre arbeidstid');

  timerStore
    .saveTotalWorkingHours(currentTenantId.value, totalWorkingHours.value)
    .then(() => {
      toast('Arbeidstimer lagret');
    })
    .catch((error) => {
      toast('Feil: Kunne ikke lagre arbeidstimer');
      genericApiErrorHandler(error);
    });
}

async function initialize() {
  await awaitTenant;

  defaultWorkingHours.value = tenantStore.getDefaultWorkingHours();

  timepickerValues.value = timePickerFormat(defaultWorkingHours.value);

  totalWorkingHours.value = tenant.value?.totalWorkingHours || 7.5;
}

initialize();
</script>

<template>
  <section class="sub-page">
    <section class="sub-page__category">
      <h2>Brukerinnstillinger</h2>

      <df-header v-if="hasTimer" class="header">Timer</df-header>

      <div v-if="hasTimer" class="checkbox-container">
        <df-checkbox
          class="checkbox"
          v-model="showWeekends"
          v-on:update="update($event, registrationKey)"
          elevate
        >
          Vis lørdag og søndag i ukes- og dagsoversikt
        </df-checkbox>
      </div>

      <df-header class="header">Ressurs</df-header>

      <div class="checkbox-container">
        <df-checkbox
          class="checkbox"
          v-model="resourceWeekends"
          v-on:update="update($event, resourceKey)"
          elevate
        >
          Vis lørdag og søndag i kalender
        </df-checkbox>
      </div>
    </section>

    <section class="sub-page__category" v-if="user !== null && hasRoles(user, [Roles.Admin])">
      <h2>Firmainnstillinger</h2>

      <df-header v-if="hasTimer" class="header">Timer</df-header>

      <div v-if="hasTimer" class="setting-container">
        <div>Standard, normal arbeidstid per dag</div>

        <div class="setting-row">
          <df-counter v-model="totalWorkingHours" :step="0.5" class="counter" />

          <df-button
            class="save-button"
            primary
            elevate
            type="submit"
            v-on:click="saveTotalWorkingHoursAction()"
            >Lagre</df-button
          >
        </div>

        <df-alert :variant="FeedbackType.Info" persistent>
          Benyttes til å vise gjenstående timeantall for standard, normal arbeidstid i dagsvisning
        </df-alert>
      </div>

      <df-header class="header">Ressurs</df-header>

      <div class="setting-container">
        <div>Standard arbeidstid for nye avtaler</div>

        <div class="setting-row" style="grid-template-columns: auto auto auto">
          <df-time v-model="timepickerValues.start" label="Angi starttid" />
          <df-time v-model="timepickerValues.end" label="Angi sluttid" />
          <df-button
            class="save-button"
            primary
            elevate
            type="submit"
            v-on:click="
              saveDefaultWorkingHours(currentTenantId, defaultWorkingHours).then(() => {
                tenantStore.loadTenant();
              })
            "
          >
            Lagre
          </df-button>
        </div>
      </div>
    </section>
  </section>
</template>

<style scoped>
.sub-page {
  gap: var(--gap-lg);
}

.sub-page__category {
  margin-bottom: var(--gap-lg);
}

h2 {
  padding-bottom: var(--gap-sm);
  border-bottom: 1px solid var(--color-border);
  margin-bottom: var(--gap-lg);
}

@media (max-width: 1000px) {
  h2 {
    width: 85%;
  }
}

.checkbox-container {
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-sm);
  overflow: hidden;
}
.checkbox-container > label {
  box-shadow: none;
  border-radius: 0px;
  padding: 0.8rem;
}

.checkbox-container > *:not(:first-child):not(:last-child) {
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
}

.header {
  margin-top: var(--gap-lg);
  margin-bottom: var(--gap-md);
}

.setting-container {
  background-color: var(--color-cardbg);
  padding: 0.8rem;
  box-shadow: var(--shadow-sm);
  border-radius: var(--radius-sm);
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
}

.setting-row {
  display: grid;
  grid-template-columns: auto auto;
  gap: var(--gap-md);
}
</style>
