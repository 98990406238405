<template>
  <div class="dropdown" :class="{ 'dropdown--open': isOpen }">
    <button
      class="resetbtn"
      v-on:click="toggle()"
      aria-haspopup
      ref="button"
      type="button"
      :aria-expanded="isOpen"
    >
      <label
        class="dropdown__button"
        :class="{
          'dropdown__button--elevate': elevate,
          'dropdown__button--fit': !label,
          'dropdown__button--fit-icon': !noCaret && !$slots.icon,
          'dropdown__button--disabled': disabled,
          'dropdown__button--primary': primary,
          'dropdown__button--active': active,
        }"
      >
        <div class="dropdown__label" v-if="label || $slots.default">{{ label }}</div>

        <div class="dropdown__text" v-if="$slots.text">
          <slot name="text"></slot>
        </div>

        <div class="dropdown__icon">
          <slot name="icon">
            <df-icon code="f107" />
          </slot>
        </div>
      </label>
    </button>

    <div class="dropdown__overlay" v-if="isOpen">
      <div
        class="overlay__menu menu"
        role="menu"
        :aria-label="menu || label"
        ref="menu"
        :class="{ 'menu--sub': menu !== '', 'menu--grid': grid != null }"
        :style="{
          top: `${top}px`,
          right: `${right}px`,
          transform: `translate(${pushX}px, ${pushY}px)`,
          'grid-template-columns': `repeat(${grid}, 1fr)`
        }"
        tabindex="0"
      >
        <div class="menu__header header">
          <button
            class="header__button"
            v-if="menu !== ''"
            v-on:click="menu = ''"
          >
            <df-icon code="f060" />
          </button>

          <div class="header__title">
            {{ menu || label }}
          </div>

          <button class="header__button" v-on:click="close()">
            <df-icon code="f00d" />
          </button>
        </div>

        <slot></slot>
      </div>

      <div class="overlay__backdrop" v-on:click="close()"></div>
    </div>
  </div>
</template>

<script src="./DfSelect.ts" lang="ts"></script>
<style src="./DfSelect.css" scoped></style>