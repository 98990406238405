export enum FeedbackType {
  Danger,
  Warning,
  Success,
  Info,
}

export type Feedback = {
  title: string;
  message: string;
  type: FeedbackType;
  show: boolean;
};

type FeedbackOpt = { title?: string; message: string; type?: FeedbackType };

export function feedback({
  title = '',
  message,
  type = FeedbackType.Danger,
}: FeedbackOpt): Feedback {
  return {
    title,
    message,
    type,
    show: true,
  };
}
