<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import useWeek from '/@composables/useWeek';
import { feedback } from '/@elements/DfAlert/DfAlertUtils';
import { useTenantStore } from '/@stores/tenant';
import { useTimerStore } from '/@stores/timer';
import { useUserStore } from '/@stores/user';
import { RegistrationStatusIds, Roles } from '/@types/ids';
import { Registration, UserRegistrations } from '/@types/timer';
import { errorText } from '/@utilities/api';
import { days, months } from '/@utilities/date';
import { downloadExcel, shorten } from '/@utilities/general';
import { date } from '/@utilities/intl';
import { maskHours, useRegistrations } from '/@utilities/registrations';
import { toast } from '/@utilities/toast';
import TypeBadge from '/@elements/TypeBadge.vue';
import { RolePermission } from '/@types/user';
import { cloneDeep, debounce } from 'lodash-es';

const loading = ref(false);

const { weekNo, year, setWeekNo, resetWeekNo } = useWeek();
watch(weekNo, () => {
  userData.value = null;
  selectedMember.value = null;
  collection.value.clear();
  load();
});

const tenantStore = useTenantStore();
const userStore = useUserStore();
const timerStore = useTimerStore();

const collection = ref(new Set());
const selectedDay = ref(0);
const sending = ref(false);
const removeIds = ref(new Set());

const feedbackObject = ref({});

type Filters = {
  projectSearch: string | null;
  userNameSearch: string | null;
  caseworkerId: number | null;
  departmentId: number | null;
};

const filtersDefault: Filters = {
  projectSearch: null,
  userNameSearch: null,
  caseworkerId: null,
  departmentId: null,
};

const filters = ref(populateFilters());

function populateFilters(): Filters {
  const storedValue = localStorage.getItem('approve/filters');

  filtersDefault.caseworkerId = userStore.user?.roles.some((r) => r.id === Roles.Saksbehandler)
    ? userStore.user.id
    : null;

  return storedValue ? JSON.parse(storedValue) : cloneDeep(filtersDefault);
}

watch(
  filters,
  debounce(() => {
    localStorage.setItem('approve/filters', JSON.stringify(filters.value));
    load();
  }, 1000),
  { deep: true },
);

const { getDateData } = useRegistrations();

const dateData = computed(() => getDateData(weekNo.value, year.value));

const approveAll = ref(null);
watch(approveAll, (val) => {
  if (val) {
    const reg = new Map(
      [...userData.value[selectedDay.value].registrations].filter(
        ([id, reg]) => reg.statusId !== RegistrationStatusIds.Approved,
      ),
    );

    collection.value = new Set([...reg.keys()]);
  } else {
    collection.value = new Set();
  }
});

const registrations = computed(() => {
  return userData.value.reduce((acc, cv) => {
    return new Map([...acc, ...cv.registrations]);
  }, new Map());
});

function approve() {
  sending.value = true;
  timerStore
    .approveRegistrations(
      [...collection.value].map((id) => {
        const reg = registrations.value.get(id);
        return {
          registrationId: id,
          hours: reg.hours,
        };
      }),
    )
    .then(() => {
      load();
      loadUserData(Number(selectedMember.value));
      collection.value = new Set();
      toast('Timer godkjent');
    })
    .catch((error) => {
      const alreadyApproved = error.response?.data.some(({ ErrorMessage }) =>
        ErrorMessage.includes('allerede blitt godkjent'),
      );
      if (alreadyApproved) {
        feedbackObject.value = feedback({
          message: 'Registrering har allerede blitt godkjent.',
        });
      } else {
        feedbackObject.value = feedback({
          message: 'Timer ble ikke godkjent',
        });
      }
    })
    .finally(() => {
      sending.value = false;
    });
}

function removeApproval(registrationId: number) {
  removeIds.value.add(registrationId);
  const reg: Registration = registrations.value.get(registrationId);

  timerStore
    .removeApproval(reg)
    .then(() => {
      load();
      loadUserData(selectedMember.value);
      toast('Time oppdatert');
    })
    .catch(() => {
      feedbackObject.value = feedback({
        title: 'Time ble ikke oppdatert',
        message: errorText(),
      });
    })
    .finally(() => {
      removeIds.value.delete(registrationId);
    });
}

function approveAllDays(userId: number) {
  sending.value = true;
  const filteredRegistrations = [...registrations.value].filter(
    ([id, reg]) => reg.statusId === RegistrationStatusIds.Created && reg.userId === userId,
  );

  timerStore
    .approveRegistrations(
      [...filteredRegistrations].map(([id, reg]) => ({
        registrationId: id,
        hours: reg.hours,
      })),
    )
    .then(() => {
      load();
      loadUserData(selectedMember.value);
      sending.value = false;
      toast('Timer godkjent');
    })
    .catch(() => {
      feedbackObject.value = feedback({
        title: 'Timer ble ikke godkjent',
        message: errorText(),
      });
    });
}

const selectedMember = ref<number | null>(null);
watch(selectedMember, () => {
  collection.value.clear();
});
const userData = ref<UserRegistrations[] | null>(null);
const userDataLoading = ref<number | null>(null);
function selectMember(id: number) {
  userDataLoading.value = id;

  loadUserData(id)
    .then((res) => {
      if (!res) return;
      selectedMember.value = selectedMember.value === id ? null : id;
      selectedDay.value = res.findIndex((i, index) => i.registrations.size > 0);
    })
    .finally(() => {
      userDataLoading.value = null;
    });
}

const downloading = ref(false);

function saveExcel() {
  downloading.value = true;
  downloadExcel({
    weekNo: weekNo.value,
    year: year.value,
    caseworkerId: filters.value.caseworkerId,
  }).finally(() => {
    downloading.value = false;
  });
}

const feedbackModel = ref({});

const filteredCaseworkerRegistrations = computed(() => {
  return {
    members: new Map(
      [...timerStore.caseworkerRegistration.members].filter(([id, reg]) => {
        const name = filters.value.userNameSearch
          ? reg.user.toLowerCase().includes(filters.value.userNameSearch.toLowerCase())
          : true;

        const department = filters.value.departmentId
          ? reg.userDepartmentId === filters.value.departmentId
          : true;

        return name && department;
      }),
    ),
  };
});

function load() {
  loading.value = true;

  return timerStore
    .loadCaseworkerRegistrations({
      caseworkerId: filters.value.caseworkerId,
      projectNameSearch: filters.value.projectSearch,
      isoWeekNumber: weekNo.value,
      isoWeekYear: year.value,
    })
    .then(() => {
      loading.value = false;
    });
}

function loadUserData(userId: number) {
  return timerStore
    .loadUserRegistrations({
      userId,
      caseworkerId: filters.value.caseworkerId,
      projectNameSearch: filters.value.projectSearch,
      isoWeekNumber: weekNo.value,
      isoWeekYear: year.value,
    })
    .then((res) => {
      userData.value = res;
      return res;
    });
}

async function initialize() {
  loading.value = true;
  await Promise.all([tenantStore.loadDepartments(), tenantStore.loadCaseworkers()]);
  await load();
  loading.value = false;
}

initialize();

// 28.08.2024 remove old localstorage entries
localStorage.removeItem('approve/caseworker');
localStorage.removeItem('approve/search');
</script>

<template>
  <div class="page">
    <df-loading v-if="loading" />

    <df-alert v-model="feedbackModel" />

    <df-header>
      <div>Uke {{ weekNo }}</div>

      <div>
        {{
          [dateData.fromDate, dateData.toDate]
            .map((d) => [d.getDate(), months[d.getMonth()]].join('. '))
            .join(' - ')
        }}
      </div>

      <template v-slot:actions>
        <df-button-group
          :entries="[
            {
              icon: 'f053',
              click: () => setWeekNo(false),
            },
            {
              icon: 'f054',
              click: () => setWeekNo(true),
            },
          ]"
        />

        <df-button v-on:click="resetWeekNo()" elevate>
          <template v-slot:icon>
            <df-icon code="f783" />
          </template>
        </df-button>
      </template>
    </df-header>

    <div class="toolbar">
      <div class="toolbar-filters">
        <div>
          <df-select3
            placeholder="Saksbehandler"
            :entries="tenantStore.caseworkers"
            display="userName"
            v-on:select="filters.caseworkerId = $event"
            elevate
          />

          <df-input-search v-model="filters.projectSearch" placeholder="Prosjektnavn" elevate />

          <df-input-search v-model="filters.userNameSearch" placeholder="Brukernavn" elevate />

          <df-select3
            placeholder="Avdeling"
            :entries="new Map([...tenantStore.departments].map(([id, name]) => [id, { name }]))"
            display="name"
            v-on:select="filters.departmentId = $event"
            elevate
          />
        </div>

        <div v-if="Object.values(filters).some((i) => i)">
          <df-button v-if="filters.caseworkerId" v-on:click="filters.caseworkerId = null">
            {{ tenantStore.caseworkers.get(filters.caseworkerId)?.userName }}
            <template v-slot:icon><df-icon code="f00d" /></template>
          </df-button>

          <df-button v-if="filters.projectSearch" v-on:click="filters.projectSearch = null">
            {{ filters.projectSearch }}
            <template v-slot:icon><df-icon code="f00d" /></template>
          </df-button>

          <df-button v-if="filters.userNameSearch" v-on:click="filters.userNameSearch = null">
            {{ filters.userNameSearch }}
            <template v-slot:icon><df-icon code="f00d" /></template>
          </df-button>

          <df-button v-if="filters.departmentId" v-on:click="filters.departmentId = null">
            {{ tenantStore.departments.get(filters.departmentId) }}
            <template v-slot:icon><df-icon code="f00d" /></template>
          </df-button>
        </div>
      </div>

      <df-dropdown>
        <df-dropdown-item to="new?selectUser=true">
          <template v-slot:icon>
            <df-icon code="2b" />
          </template>
          Ny registrering på bruker
        </df-dropdown-item>

        <df-dropdown-item to="admin">
          <template v-slot:icon>
            <df-icon code="e247" />
          </template>
          Se feilede registreringer
        </df-dropdown-item>
      </df-dropdown>

      <df-button
        v-on:click="saveExcel()"
        :disabled="downloading || !userStore.hasRolePerm(RolePermission.RegistrationExport)"
      >
        Eksporter excel
        <template v-slot:icon>
          <df-loading no-float v-if="downloading" />
          <df-icon v-else code="f1c3" />
        </template>
      </df-button>
    </div>

    <df-alert v-model="feedbackObject" />

    <table class="card" v-if="timerStore.caseworkerRegistration">
      <thead>
        <tr>
          <th class="table-actions"></th>
          <th class="name">Navn</th>
          <th></th>
          <th class="number" v-for="day in dateData.days" :key="day.name">
            <div>{{ day.name.slice(0, 3) }}</div>
            <div style="font-size: 0.8rem; font-weight: normal">{{ day.date.getDate() }}</div>
          </th>
          <th class="number">Sum</th>
        </tr>
      </thead>

      <tbody>
        <tr v-if="filteredCaseworkerRegistrations.members.size === 0">
          <td colspan="10" style="text-align: center">Ingen registreringer</td>
        </tr>
        <template v-for="[id, member] in filteredCaseworkerRegistrations.members" :key="id">
          <tr>
            <td class="hh">
              <div class="actions">
                <df-button v-on:click="selectMember(id)">
                  Detaljer
                  <template v-slot:icon>
                    <df-loading v-if="userDataLoading === id" no-float />
                    <df-icon v-else :code="selectedMember === id ? 'f078' : 'f054'" />
                  </template>
                </df-button>
              </div>
            </td>

            <td class="name">{{ member.user }}</td>

            <td>
              <div class="badge" v-if="member.finishedWeek">Uke ferdig</div>
            </td>

            <td class="number" v-for="(day, index) in member.days" :key="index">
              <div class="stack">
                <div
                  v-if="day.sumHours || day.sumDays"
                  class="badge"
                  :class="{
                    'badge--success': day.allTimesatserApproved,
                    'badge--warning': day.rejectedCount || day.feedbackCount,
                  }"
                  style="grid-row: 1"
                >
                  <template v-if="day.sumDays">{{ maskHours(day.sumDays) }}d</template>

                  <template v-if="day.sumHours">{{ maskHours(day.sumHours) }}t</template>
                </div>

                <div
                  v-if="day.sumExpenses"
                  class="badge"
                  :class="{
                    'badge--success': day.allExpensesApproved,
                    'badge--warning': day.rejectedCount || day.feedbackCount,
                  }"
                  style="grid-row: 2"
                >
                  {{ maskHours(day.sumExpenses) }}
                </div>
              </div>
            </td>

            <td class="number">
              <div class="stack">
                <div class="badge">
                  <template v-if="member.sumDays">{{ maskHours(member.sumDays) }}d</template>
                  {{ maskHours(member.sumHours) }}t
                </div>
                <div class="badge">{{ maskHours(member.sumExpenses) }}</div>
              </div>
            </td>
          </tr>

          <tr style="vertical-align: top" v-if="selectedMember === id">
            <td>
              <div class="day-list">
                <button
                  class="day-btn"
                  :class="{ 'day-btn--active': selectedDay === index }"
                  v-for="(day, index) in userData"
                  :key="index"
                  v-on:click="
                    selectedDay = index;
                    approveAll = null;
                  "
                  :disabled="day.registrations.size === 0"
                >
                  <div>{{ days[index] }}</div>

                  <div class="badges">
                    <div
                      v-if="day.totalExpenses"
                      class="badge"
                      :class="{
                        'badge--success': day.allRegistrationsApproved,
                      }"
                    >
                      {{ maskHours(day.totalExpenses) }}
                    </div>

                    <div
                      class="badge"
                      :class="{
                        'badge--success': day.allRegistrationsApproved,
                        'badge--warning': day.totalHours > 7.5,
                      }"
                    >
                      {{ maskHours(day.totalDays) }}d
                    </div>

                    <div
                      class="badge"
                      :class="{
                        'badge--success': day.allRegistrationsApproved,
                        'badge--warning': day.totalHours > 7.5,
                      }"
                    >
                      {{ maskHours(day.totalHours) }}t
                    </div>
                  </div>
                </button>

                <df-button
                  v-on:click="approveAllDays(id)"
                  primary
                  elevate
                  :disabled="member.allDaysApproved || sending"
                >
                  Godkjenn uke
                  <template v-slot:icon>
                    <df-loading v-if="sending" no-float />
                    <df-icon v-else code="f00c" />
                  </template>
                </df-button>
              </div>
            </td>

            <td colspan="10">
              <ul class="card">
                <li>
                  <div class="title-card">
                    <df-button
                      v-on:click="approveAll = approveAll === id ? null : id"
                      :disabled="member.days[selectedDay].allRegistrationsApproved"
                    >
                      <template v-slot:icon>
                        <df-icon :code="approveAll === id ? 'f14a' : 'f0c8'" />
                      </template>
                    </df-button>

                    <div>
                      <div>{{ date(member.days[selectedDay].date) }}</div>
                      <div class="badge" v-if="member.days[selectedDay].isFinished">Dag ferdig</div>
                    </div>

                    <df-button
                      v-on:click="approve()"
                      :disabled="collection.size === 0 || sending"
                      primary
                      elevate
                    >
                      Godkjenn {{ collection.size }} markerte
                      <template v-slot:icon>
                        <df-loading v-if="sending" no-float />
                        <df-icon v-else code="f00c" />
                      </template>
                    </df-button>
                  </div>
                </li>

                <table class="ttt">
                  <tr>
                    <td style="width: 50px"></td>
                    <td style="width: auto">Prosjekt</td>
                    <td style="width: 10%">Saksbehandler</td>
                    <td style="width: 10%">Status</td>
                    <td style="width: 10%">Artikkel</td>
                    <td style="width: 10%">Aktivitet</td>
                    <td style="width: 10%">Beskrivelse</td>
                    <td style="width: 10%">Etikett</td>
                    <td style="width: 10px" class="--right"></td>
                    <td style="width: 10px" class="--right">Antall</td>
                    <td style="width: 50px"></td>
                  </tr>

                  <tr
                    v-for="[id, reg] in userData[selectedDay].registrations"
                    :key="id"
                    :class="{ 'reg--dimmed': reg.statusId > RegistrationStatusIds.FeedbackSent }"
                  >
                    <td>
                      <div style="display: flex">
                        <df-button
                          v-if="reg.statusId === RegistrationStatusIds.Approved"
                          v-on:click="removeApproval(id)"
                          :disabled="removeIds.has(id)"
                        >
                          <template v-slot:icon>
                            <df-loading v-if="removeIds.has(id)" no-float />
                            <df-icon v-else code="f00d" />
                          </template>
                        </df-button>

                        <df-checkbox
                          v-else
                          :value="id"
                          v-model="collection"
                          :disabled="reg.statusId > RegistrationStatusIds.FeedbackSent"
                        />
                      </div>
                    </td>

                    <td>{{ reg.projectName }}</td>

                    <td>{{ reg.projectCaseworker }}</td>

                    <td>
                      <div
                        class="badge"
                        :class="{
                          'badge--success': reg.statusId >= RegistrationStatusIds.Approved,
                          'badge--warning': [
                            RegistrationStatusIds.Rejected,
                            RegistrationStatusIds.FeedbackSent,
                          ].includes(reg.statusId),
                          'badge--danger': reg.statusId === RegistrationStatusIds.TransferFailed,
                        }"
                      >
                        <df-icon
                          v-if="reg.statusId === RegistrationStatusIds.Approved"
                          code="f058"
                        />
                        {{ reg.statusName }}
                      </div>
                    </td>

                    <td>{{ reg.articleName }}</td>

                    <td>{{ reg.activityName }}</td>

                    <td>{{ shorten(reg.description, 30) }}</td>

                    <td>
                      <TypeBadge v-if="reg.articleLabels.size > 0" :types="reg.articleLabels" />
                    </td>

                    <td class="--right">
                      <div class="badge" v-if="reg.notesCount > 0">
                        <df-icon code="f27a" />
                        {{ reg.notesCount }}
                      </div>
                    </td>

                    <td class="--right">
                      <TypeBadge :types="reg.labels">
                        {{ maskHours(`${reg.hours}${reg.isExpense ? '' : reg.isDay ? 'd' : 't'}`) }}
                      </TypeBadge>
                    </td>

                    <td>
                      <div style="display: flex; justify-content: flex-end">
                        <df-button :to="`approval/${id}`">
                          <template v-slot:icon>
                            <df-icon code="f05a" />
                          </template>
                        </df-button>
                      </div>
                    </td>
                  </tr>
                </table>
              </ul>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <df-modal v-if="$route.matched.length > 1">
      <router-view></router-view>
    </df-modal>
  </div>
</template>

<style scoped>
.actions {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: var(--gap-md);
}

table {
  border-collapse: collapse;
  text-align: left;
}

thead {
  align-items: flex-start;
  align-content: flex-start;
  vertical-align: top;
  border-bottom: 1px solid var(--color-border);
}

th,
td {
  padding: var(--gap-md);
}

tr:not(:last-child) {
  border-bottom: 1px solid var(--color-border);
}

.name {
  width: auto;
}

.number {
  width: 80px;
  text-align: right;
}

.page > * {
  max-width: 100%;
  max-width: 1600px;
}

ul {
  padding: 0;
}

li {
  padding: var(--gap-md);
}

li:not(:last-child):not(:first-child) {
  border-bottom: 1px solid var(--color-border);
}

ul {
  display: grid;
}

.title-card {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  gap: var(--gap-md);
  align-items: center;
  background-color: var(--color-bg);
  margin: calc(var(--gap-md) * -1);
  padding: var(--gap-md);
}

.day-list {
  display: grid;
  gap: var(--gap-md);
}

.day-btn {
  background: none;
  border: none;
  text-align: left;
  padding: var(--gap-md);
  background-color: var(--color-bg);
  display: grid;
  grid-template-columns: 1fr max-content;
  border-radius: var(--radius-sm);
  color: var(--color-link);
  font-weight: 600;
  border: 1px solid var(--color-border);
}

.day-btn:hover:not(.day-btn:disabled) {
  background-color: var(--color-hover);
}

.day-btn:disabled {
  opacity: 0.5;
}

.day-btn--active {
  background-color: var(--color-active);
}

.toolbar {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-template-columns: 1fr;
  gap: var(--gap-md);
  align-items: flex-start;
}

.table-actions {
  width: 25ch;
}

.badges {
  display: flex;
  gap: var(--gap-sm);
}

.reg--dimmed {
  opacity: 0.5;
}

.ttt {
  font-size: 0.8rem;
  table-layout: fixed;
}

.--right {
  text-align: right;
}

.reg--dimmed:hover {
  opacity: 1;
}

.stack {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: var(--gap-sm);
  justify-content: flex-end;
  justify-items: flex-end;
}

.toolbar-filters {
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);

  & > * {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: var(--gap-sm);
  }
}
</style>
