<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useActivitiesStore } from '/@stores/timer-activities';
import { watch } from 'vue';
import { formatMsToNum, getDiffInMs } from '/@utilities/date';

type Activity = {
  id: number | null;
  hours: number;
  description: string;
  fromDateTime?: string | null;
  toDateTime?: string | null;
};

const model = defineModel<Activity>({ required: true });

watch(
  () => [model.value.fromDateTime, model.value.toDateTime],
  () => {
    if (!model.value.fromDateTime || !model.value.toDateTime) return;
    model.value.hours = formatMsToNum(
      getDiffInMs(model.value.fromDateTime, model.value.toDateTime),
    );
  },
);

const props = defineProps<{
  index: number;
  requireFromToDate?: boolean;
  isCorrection?: boolean;
  canRemove?: boolean;
}>();

const emit = defineEmits<{ (e: 'remove', index: number): void }>();

const { activities } = storeToRefs(useActivitiesStore());
</script>

<template>
  <df-select3
    v-model="model.id"
    :entries="activities"
    display="name"
    :disabled="isCorrection"
    elevate
  />

  <div class="input-group" v-if="requireFromToDate">
    <df-time label="Fra" v-model="model.fromDateTime" elevate />

    <df-time label="Til" v-model="model.toDateTime" elevate />
  </div>

  <df-counter
    v-else
    v-model="model.hours"
    :step="0.5"
    :min-value="isCorrection ? null : 0"
    elevate
  />

  <df-button v-on:click="emit('remove', index)" :disabled="!canRemove || isCorrection" elevate>
    <template v-slot:icon>
      <df-icon code="f00d" />
    </template>
  </df-button>

  <df-input
    class="--grid-span"
    v-model="model.description"
    multiline
    :rows="1"
    elevate
    :disabled="isCorrection"
  />

  <div v-if="$slots.default" class="--grid-span">
    <slot name="default"></slot>
  </div>
</template>

<style scoped>
.input-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-sm);
}

.--grid-span {
  grid-column: 1/-1;
}
</style>
